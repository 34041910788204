.collapsable-tab {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  background: rgba(20, 18, 10, 0.03);
  color: black;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 12px;
}

.collapsable-tab .head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  border: none;
  background: none;

  font-size: 16px;
}

.collapsable-tab .content {
  max-width: fit-content;
  font-size: 16px;
  padding: 10px;
  margin-top: 14px;
}

.collapsable-tab .chevron {
  width: 24px;
  height: 24px;
  color: #1b3732;

  transition: transform 0.3s;
}

.collapsable-tab .chevron.open {
  transform: rotateZ(180deg);
}
